
.chat-container {
    background-color: #c997ff7a;
    background-size: cover;
    background-repeat: repeat; 
    background-position: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.sidebar {
  position: fixed;
  top: 0;
  left: -250px; /* Oculta el menú fuera de la pantalla */
  width: 250px;
  height: 100%;
  background: #fff;
  transition: left 0.3s ease; /* Efecto de transición suave */
  z-index: 1000;
  overflow-y: auto;
}

.sidebar.open {
  left: 0; /* Muestra el menú cuando está abierto */
  padding: 10px;
  padding-bottom: 50px;
}

/* Estilo para el botón de alternar el menú desplegable */
.sidebar-toggle-btn {
  display: block;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.discussion-list-container {
  /* Estilos para el contenedor de la lista de discusiones */
  overflow-y: auto;
  max-height: calc(100% -100px); /* Altura máxima del contenedor de la lista */
}

.menu {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.menu-sidebar {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 20px;
}

.out-sidebar-button {
  flex-basis: 20%;
  background-color: transparent;
  color: #7b1b9e;
  border: none;
  font-size: 1.5rem;
  border-radius: 5px;
  height: 30px;
  transition: 0.3s;
  margin-right: 30%;
}

.chatbot-signout {
  flex-basis: 50%;
  background-color: #f3f7fe;
  color: #701b9e;
  font-size: 0.8em;
  border: none;
  border-radius: 5px;
  height: 30px;
  transition: 0.3s;
}



.chatbot-signout:hover {
  background-color: #821b9e;
  box-shadow: 0 0 0 2px #3b82f65f;
  color: #fff;
}
.out-sidebar-button:hover {
  background-color: #821b9e;
  box-shadow: 0 0 0 2px #3b82f65f;
  color: #fff;
}
.main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chatbot-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
}

.chat-title-container{
    width: 100vw;
    height: fit-content;
    align-items: center;
}
  

  .chatbot-container {
    width: 80%; 
    max-width: 100vw;
    height: 70%; 
    max-height: 70vh;
    overflow-y: auto; 
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0ca;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 1rem;
    margin: 10px auto;
    position: relative;
  }
  .chatbot-container::-webkit-scrollbar {
    width: 5px;

  }
  .chatbot-container::-webkit-scrollbar-thumb {
    background-color: #adadad; /* Color del scrollbar thumb (desplazador) */
    border-radius: 1rem; /* Bordes redondeados */
  }
  
  .chatbot-container::-webkit-scrollbar-track {
    background-color: #d8e2f371; 
    border-radius: 1rem;
  }
  
  .chatbot-title {
    display: flex;
    flex-basis: 70%;
    align-items: center;
    justify-content: center;
    font-size: 2.5vh;
    padding-inline: 1%;
    color: #821b9e;
  }
  
  .chatbot-prompt {
    width: 60%;
    background-color: #e2f7fe;
    border: 1px solid #cb8bf8;
    border-radius: 5px;
    padding: 8px;
    margin: 8px 0;
  }
  
  .chatbot-response {
    background-color: transparent;
    border: none;
    padding: 8px;
    margin: 8px 0;
    display: flex;
    flex-direction: column;
  }
  
  
  
  .chat-prompt-message {
    display: flex;
    position: relative;
    font-size: 0.7rem;
    background-color: #671b9e;
    padding: 0.5em;
    z-index: 5;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    color: white; 
    margin-left: auto;
    width: fit-content;
    max-width: 80%;
    justify-content: right;

  }
  
  .chat-response-message {
    display: flex;
    font-size: 0.7rem;
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
    padding: 0.5em;
    z-index: 5;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: fit-content;
    max-width: 80%;
    color: black; 
    align-self: flex-end; 
  }
  p {
    margin: 5px 0;
  }
  .prompt{
    max-width: 100%;
  }

  .message-date-prompt {
    display: flex;
    font-size: 0.5rem;
    color: grey;
    margin-top: 1vw;
    margin-bottom: 2vw;
   justify-content: right;
  }
  .message-date-response {
    font-size: 0.5rem;
    margin-top: 1vw;
    color: grey;
    margin-bottom: 2vw;
  }
  .chatbot-prompt-input {
    width: 50%;
    background-color: white;
    border: 1px solid #d9e9f4;
    border-radius: 5px;
    padding: 1%;
    font-size: 0.7rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  .chatbot-submit {
    font-size: 0.7rem;
    background-color: #f3f7fe;
    color: #6c1b9e;
    border: none;
    border-radius: 5px;
    padding: 1%;
    margin-inline: 1%;
    transition: 0.3s;
    box-shadow: 5px 15px 25px rgba(0,0, 0, 0.35);
  }
  .chatbot-text {
    display: none;
  }
  
  .chatbot-icon {
    display: inline;
  }
  .chatbot-submit:hover {
    background-color: #751b9e;
    box-shadow: 0 0 0 2px #ca3bf65f;
    color: #fff;
  }
  .chatbot-submit[disabled] {
    background-color: #ccc;
    box-shadow: none;
    color: white;
  }
  .loading-indicator {
    position: relative;
    display: flex;
    font-size: 0.5rem;
    color: #5f1b93;
    justify-content: center;
    align-items: center;
    animation: loading-pulse 0.5s infinite alternate; 
  }
  
  .add-discussion-button {
    background-color: #f3f7fe;
    color: #7b1b9e;
    border: none;
    border-radius: 5px;
    font-size: 0.8em;
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    transition: 0.3s;
  }
  .add-discussion-button:hover {
    background-color: #7b1b9e;
    box-shadow: 0 0 0 2px #3b82f65f;
    color: #fff;
  }
  .add-discussion-button[disabled] {
    background-color: #adaeaf;
    color: #7b1b9e;
    box-shadow: none;
  }
  
  .discussion-list {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Espacio entre botones */
  }
  .discusionId-item{
    color: #f3f7fe;
    background-color: #771b9e;
    border: none;
    border-radius: 5px;
    padding: 8px;
    font-size: 0.8em;
    transition: 0.3s;
    position: relative; /* Asegura que el botón de eliminar se posicione correctamente */
    padding-right: 30px;
  }  
  .discusionId-item:hover {
    background-color: white;
    color:#771b9e;
    box-shadow: 0 0 0 2px #8f3bf65f;
  }
  .delete-discussion-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: 5px;
    background-color: #aaaaaa; /* Puedes ajustar el color según tu preferencia */
    color: #fff;
    border: none;
    border-radius: 5px;
    font-style: bold;
    padding: 4px 8px;
    cursor: pointer;
  }
  
  
.bottom-file {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.bottom-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.bottom-file-text {
  margin-right: 10px;
}

.bottom-file-icon {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIj4KPHN2ZyBoZWlnaHQ9IjEwMCUiIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwMCUiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogIDxjaXJjbGUgY3g9IjM1JSIgY3k9IjUwJSIgcj0iMjUlIiBzdHlsZT0iZmlsbDpyZWQ7IiAvPgogIDxjaXJjbGUgY3g9IjYwJSIgY3k9IjUwJSIgcj0iMjUlIiBzdHlsZT0iZmlsbDpibHVlOyIgLz4KPC9zdmc+Cg==');
  background-repeat: no-repeat;
  background-position: center;
}
  
  input[type="file"]{
    display: none;
  }
  label{
    display: inline-block;
    text-transform: uppercase;
    color:#fff;
    background: #9c13f1;
    text-align: center;
    padding: 5px 8px;
    font-size: 10px;
    letter-spacing: 1px;
    user-select: none;
    cursor: pointer;
    box-shadow: 5px 5px 15px rgba(0,0, 0, 0.35);
    border-radius: 3px;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  .uploadBtn p{
    display: none;
    text-transform: none;
  }
  .btn-icon{
    margin-right: 0px;
    font-size: 20px;
  }
  
  label i{
    font-size: 15px;
    margin-right: 8px;
  }
  label:active{
    transform: scale(0.9);
  }

  .chatbot-firebase-link{
    color: #c98bf8;
    overflow-wrap: break-word;
  }
  
  .checkbox-p{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: white; 
    border-radius: 0.3rem;
    margin: 1%;

  }

  .checkbox-p p{
    font-size: 0.7em;
    margin-left:5px;
    color :#911b9e42;
    display: none;
  }
  

  @media screen and (min-width: 600px){

.chat-container {
  display: flex;
  flex-direction: row;
}
.chatbot-container {
  width: 90vw;
  height: 50vh;
  max-width: 90vw;
  max-height: 70vh;
}
    .chat-title-container{
      width: 100%;
      max-height: 10vh;
    }
    .chatbot-title {
      font-size: 2rem;
    }
    .chatbot-signout{
      font-size: 0.8em;
    }
    .chat-prompt-message,
    .chat-response-message,
    .chatbot-prompt-input {
      font-size: 0.8rem; 
    }

    .chatbot-submit {
      font-size: 0.7rem;
      background-color: #f3f7fe;
      color: #1b5a9e;
      border: none;
      border-radius: 5px;
      height: 40px;
      width: 15%;
      min-width: fit-content;
      padding: 1%;
      margin-inline: 1%;
      transition: 0.3s;
      box-shadow: 5px 15px 25px rgba(0,0, 0, 0.35);
      font-size: 0.9rem; 
    }
    .chatbot-text {
      display: inline;
    }
    
    .chatbot-icon {
      display: none;
    }
    .chat-prompt-message,
    .chat-response-message {
      padding: 1em;
    }
    .chatbot-form {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px; /* Ajusta el margen superior según sea necesario */
    }
    
    .chatbot-prompt-input {
      width: 60%;
    }
    .chatbot-submit {
      width: 12%;
      margin-bottom: 0;
    }
    .message-date-prompt,
    .message-date-response {
      font-size: 0.5rem;
    }
    p {
      margin: 1vh 0;
    }
    .loading-indicator {
      font-size: 0.6rem;
    }
  
  
    .sidebar-toggle-btn {
      display: block;
      position: absolute;
      top: 30px;
      left: 30px;
      font-size: 40px;
      background: none;
      border: none;
      cursor: pointer;
    }
    .checkbox-p p{
      font-size: 0.7em;
      margin-left:5px;
      color :#9e1b91;
      display: flex;
    }
    .uploadBtn {
      padding: 0 3px;
    }
    .uploadBtn p{
      display: flex;
    }
    .btn-icon{
      margin-right: 5px;
    }
    .button-file label {
      display: flex; 
    }
  }
  
@media screen and (min-width: 1024px){
  .chatbot-title {
    font-size: 3.2rem;
  }
  .chatbot-container {
    max-width: 80vw;
    max-height: 60vh;
  }
  .chatbot-signout{
    font-size: 0.8em;
  }
  .chat-prompt-message,
  .chat-response-message,
  .chatbot-submit {
    font-size: 1rem; 
  }
  .chat-prompt-message,
  .chat-response-message {
    padding: 1em;
  }
  .message-date-prompt,
  .message-date-response {
    font-size: 0.6rem;
  }
  p {
    margin: 0.5vh 0;
  }
  .loading-indicator {
    font-size: 0.8rem;
  }

}

@keyframes loading-pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2); 
  }
}