
.login-view-container {
    background-image: url('../../assets/marketing_background.jpg');
  background-size: cover;
  background-repeat: repeat; /* Repetir la imagen si es necesario */
  background-position: center;
  width: 100vw;
  height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .login-container {
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1.2rem;
    height:fit-content;
    width: max-content;
  }
  .login-title{
    font-size: 2rem;
    color: #041b28;
  }
  .login-image{
    max-width: 30%;


  }
  
  .login-button {
    background-color: #730a81; 
    color: #f3f3f3; 
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10%;
    z-index: 5;
  }
  
  .login-button:hover {
    background-color: #e0ecf3; 
    color: #0a5681; 
  }
  .loading-container {
    color: white;
    width: 100vw;
    height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .login-loading{
      width: 30%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: loading-pulse 0.5s infinite alternate; 
  }
    
    @keyframes loading-pulse {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.2); 
      }
    }
  